import parsePhoneNumber from "libphonenumber-js";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const formatCurrency = (value: string) => {
  if (value === "") return "";

  if (isNaN(Number(value))) return `${value}`;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(Number(value)).replace("$", "").replace(".00", "");
};

export const formatTwoDecimalsMax = (value: string) => {
  return !value || isNaN(Number(value)) ? value : Number(value).toFixed(2).toString().replace(".00", "");
};

export const removeHttpFromUrl = (url: string) => {
  if (!url) return "";

  if (url.trim().startsWith("http")) {
    return url.replace("http://", "").replace("https://", "");
  }

  return url;
};

export const formatUrl = (url: string) => {
  if (!url) return "";

  if (url?.startsWith("http")) {
    return url;
  }

  return `https://${url}`;
};

export const formatDecimal = (number: number | string) => {
  const toFixed = Number(number) % 1 > 0 ? 2 : 0;

  return Number.parseFloat(number as string)
    .toFixed(toFixed)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPhoneNumber = (phoneNumberString = "") => {
  if (!phoneNumberString) return "";

  const phoneNumber = parsePhoneNumber(phoneNumberString, "US");
  return phoneNumber?.formatNational() || null;
};

export enum DateFormat {
  DEFAULT = "MMM D YYYY, h:mm a",
  DEFAULT_WITHOUT_TIME = "MMM D, YYYY",
  DEFAULT_WITHOUT_YEAR = "MMM D, h:mm a",
  DEFAULT_WITHOUT_YEAR_TIME = "MMM D",
}

const getDateFormat = (value: string | Date, currentTime: Dayjs, showTime = false) => {
  return dayjs(value).isSame(currentTime, "year")
    ? showTime
      ? DateFormat.DEFAULT_WITHOUT_YEAR
      : DateFormat.DEFAULT_WITHOUT_YEAR_TIME
    : showTime
    ? DateFormat.DEFAULT
    : DateFormat.DEFAULT_WITHOUT_TIME;
};

export const formatDateTime = (value: string | Date, showTime = false, showRelativeTime = false) => {
  if (!value) {
    return "";
  }
  const currentTime = dayjs();
  const dateFormat = getDateFormat(value, currentTime, showTime);

  if (showTime && showRelativeTime) {
    return dayjs(value).fromNow();
  }

  if (showTime) {
    return dayjs(value).format(dateFormat);
  }

  if (dayjs(value).isAfter(currentTime)) {
    return dayjs(value).format(dateFormat);
  }

  if (dayjs(value).isAfter(dayjs().subtract(1, "minute"))) {
    return "a few seconds ago";
  }

  if (dayjs(value).isAfter(dayjs().subtract(1, "hour"))) {
    return `${currentTime.diff(dayjs(value), "minute")}m ago`;
  }
  if (dayjs(value).isAfter(dayjs().subtract(24, "hour"))) {
    return `${currentTime.diff(dayjs(value), "hour")}h ago`;
  }
  if (dayjs(value).isAfter(dayjs().subtract(1, "week"))) {
    return `${currentTime.diff(dayjs(value), "day")}d ago`;
  }
  return dayjs(value).format(dateFormat);
};

export const formatDateFull = (value: string) => {
  return dayjs(value).format(DateFormat.DEFAULT);
};

export const formatDate = (value: string) => {
  return dayjs(value).format(DateFormat.DEFAULT_WITHOUT_TIME);
};

export const formatAddress = (address: {
  address_line_1?: string;
  city?: string;
  state?: string;
  zip_code?: string;
}) => {
  const { address_line_1: addressLine1, city, state, zip_code: zipCode } = address;

  let finalAddress = addressLine1 ? addressLine1 : "";

  if (city && finalAddress) {
    finalAddress += `, ${city}`;
  } else if (city) {
    finalAddress += city;
  }

  if (finalAddress && (state || zipCode)) {
    finalAddress += `, ${state || ""} ${zipCode || ""}`;
  } else if (state || zipCode) {
    finalAddress += `${state || ""} ${zipCode || ""}`;
  }

  return finalAddress;
};

// removes  params added by supabase with #
export const getSanitizedRedirectUrl = (url?: string | null) => {
  if (!url) return;
  return url.split("#")[0];
};

import { NestedViewStack } from "context/NestedViewContext";
import {
  AlgoliaSearchTable,
  ColumnConditionalRule,
  MenuItem,
  Page,
  PageAction,
  TableColumnType,
  TableFilterType,
  TablePageViewColumn,
  TablePageViewTab,
  TableViewConfig,
  UserHistory
} from "types/baTypes";
import { RecordItem, NestedViewRecordProps } from "types/common";
import {
  ApiPageColumn,
  ApiColumnCondition,
  ApiColumnRule,
  ApiPage,
  ApiPageViews,
  ApiPageViewColumn,
  ApiView,
  ApiPageAction,
  ApiColumnSection,
  ApiViewColumnSection,
  ApiAlgoliaSearchTable,
  ApiMenuItem,
  ApiPageViewTabs,
  ApiUserHistory
} from "types/apiTypes";
import { FILTER_OPERATOR, LookupTypes, ViewOption } from "./constants";
import { getPropertyFromRecordSource } from "./dataUtils";

// Checks if currentTablePage is a tab in the nested view stack
// Returns the query params of the path to the record if true
export const getRecordInNestedView = (nestedViewStack?: NestedViewStack, currentTablePage?: Page) => {
  if (!nestedViewStack?.length || !currentTablePage?.table_name || nestedViewStack?.length > 1) {
    return {
      isNestedView: false
    };
  }
  const currentNestedView = nestedViewStack[nestedViewStack.length - 1];
  const detailTabs = currentNestedView.pageData?.views?.find((view) => view.viewType === ViewOption.DETAIL_MAIN)?.tabs;
  if (!currentNestedView || !detailTabs?.length) {
    return {
      isNestedView: false
    };
  }
  const currentTab = detailTabs.find((tab) => tab.page?.id === currentTablePage.id);
  if (!currentTab) {
    return {
      isNestedView: false
    };
  }
  // One level nesting
  if (nestedViewStack.length === 1) {
    return {
      isNestedView: true,
      queryParams: [
        {
          tabId: currentTab.id,
          tabPath: currentTab.page?.path || "",
          nestedRecordId: currentNestedView.recordId
        }
      ]
    };
  }
};

export const getPageViewRoute = (page?: Page, isMobile?: boolean, isActivityTable?: boolean) => {
  const defaultView = page?.default_view || (isActivityTable ? ViewOption.ACTIVITY : ViewOption.GRID);
  const mobileDefaultView = page?.mobile_default_view || ViewOption.CARD;

  const viewsAvailable = [...(page?.views || []), ViewOption.NOTE];
  const hasMobileView = mobileDefaultView ? viewsAvailable.some((view) => view === mobileDefaultView) : false;

  if (isMobile && hasMobileView) {
    return mobileDefaultView;
  }

  return defaultView;
};

export const getNestedPageUrl = (
  nestedViewRecordProps: NestedViewRecordProps,
  recordId: string,
  pathParts: string[]
) => {
  let finalURL = "";

  nestedViewRecordProps?.queryParams?.forEach((param) => {
    if (nestedViewRecordProps?.isNestedView) {
      finalURL += `/${param.nestedRecordId}`; // Record id is of the parent
      finalURL += `${param.tabPath}`;
    } else {
      finalURL += `${param.tabPath}`;
      finalURL += `/${param.nestedRecordId}`; // Record id is of the parent
    }
  });
  let finalPath = "";
  if (pathParts.includes("r") && pathParts.length > 3) {
    finalPath = nestedViewRecordProps?.isNestedView
      ? `/${pathParts.join("/")}${finalURL}/${recordId}`
      : `/${pathParts.join("/")}/${recordId}${finalURL}`;
  }
  return finalPath;
};

export const mapApiColumnRuleToPageColumnRule = (apiColRules: ApiColumnRule[]) => {
  return apiColRules.map((apiColRule) => ({
    id: apiColRule.id,
    columnRules: apiColRule.columnRules?.map((rule: ApiColumnCondition) => ({
      id: rule.id,
      columnId: rule.pageColumn?.columns_id,
      pageColumnId: rule.pageColumn?.id,
      operator: rule.operator,
      value: rule.value || rule.option_value,
      field: rule.field,
      lookupColumnNameField: rule.lookup_column_name_field,
      filterGroup: rule.filter_group
    })) as ColumnConditionalRule[],
    ruleConfig: apiColRule.rule_config
  }));
};

export const mapApiColRuleToFilters = (apiFilters: ApiColumnRule) => {
  return apiFilters.columnRules?.map((rule) => {
    return {
      id: rule.id,
      columnId: rule.pageColumn?.columns_id,
      pageColumnId: rule.pageColumn?.id,
      filterOperator: rule.operator,
      filterValue: rule.value || rule.option_value,
      filterField: rule.field,
      filterLookupPath: rule.filter_lookup_path,
      originalRuleItem: apiFilters,
      recordIdSource: rule.record_id_source,
      includeNull: rule.include_null,
      isParentSynced: !!rule.is_parent_synced,
      isUserVisible: !!rule.is_user_visible,
      filterDbType: rule.filter_db_type
    };
  }) as TableFilterType[];
};

export const mapApiColSectionsToSections = (
  apiColSections?: Array<{ id: string; section: ApiColumnSection | ApiViewColumnSection }>
) => {
  if (!apiColSections?.length) return [];
  return apiColSections.map((apiColSection) => ({
    id: apiColSection.section.id,
    title: apiColSection.section.title,
    description: apiColSection.section?.description,
    webhookUrl: apiColSection.section?.webhook_url,
    config: apiColSection.section?.config,
    sort: apiColSection.section?.sort,
    isClose: apiColSection.section?.is_close,
    isAdminOnly: apiColSection.section?.is_admin_only,
    viewId: apiColSection.section?.view_id,
    pageViewId: apiColSection.section?.page_view_id,
    userId: apiColSection.section?.for_user_id,
    filters: apiColSection.section?.filter ? mapApiColRuleToFilters(apiColSection.section.filter) : undefined,
    pageViewColumns: apiColSection.section?.pageViewColumns?.map((pvCol) => ({
      columnId: pvCol.ui_pages_views_columns_id?.columns_id
    })),
    viewColumns: (apiColSection.section as ApiViewColumnSection)?.viewColumns?.map((vCol) => ({
      columnId: vCol.ui_views_columns_id?.columns_id
    }))
  }));
};

export const mapPageColumnsToType = (
  apiColumns: ApiPageColumn[],
  pageViews: ApiPageViews[],
  pageId: string,
  isViewColumn?: boolean
): TableColumnType[] => {
  const convertedCols = apiColumns.map((pageCol) => {
    return {
      id: pageCol.column.id,
      name: pageCol.column.name,
      nickname: pageCol.column.nickname,
      description: pageCol.column.description,
      header: pageCol.column.header,
      createdAt: pageCol.column.created_at,
      pageColumnId: isViewColumn ? undefined : pageCol.id,
      viewColumnId: isViewColumn ? (pageCol as RecordItem).viewColumnId : undefined, // This is set when mapping custom view columns
      pageId: pageCol.page_id,
      isChild: !!pageCol.is_child,
      hasFilter: !!pageCol.column.has_filter,
      filterSortOrder: pageCol.column.filter_sort_order,
      hasBulkEdit: !!pageCol.column.has_bulk_edit,
      cellConfig: pageCol.column.cell_config,
      isActive: !isViewColumn ? !!pageCol.is_active : !!pageCol.column.is_active, // If it's a custom view column there is no page column
      type: pageCol.column.type,
      isLookup: !!pageCol.column.is_lookup,
      isFormula: !!pageCol.column.is_formula,
      lookupPath: pageCol.column.lookup_path,
      isRollupSource: !!pageCol.column.is_rollup_source,
      columnOptionsLookUp: pageCol.column.column_options_lookup,
      columnOptionSortOrder: pageCol.column.column_options_sort,
      columnGroupBySortOrder: pageCol.column.column_options_group_sort,
      isMultiple: !!pageCol.column.is_multiple,
      isEditable: !!pageCol.column.is_editable,
      enableInlineEditing: !!pageCol.column.is_inline_editable,
      isSelect: !!pageCol.column.is_select,
      dbType: pageCol.column.db_type,
      createPageId: pageCol.column.create_page_id,
      createPageDefaultValues: pageCol.column.create_page_defaults,
      defaultValues: pageCol.column.default_values,
      formula: pageCol.column.formula,
      rollupConfig: pageCol.column.rollup_config,
      configErrors: pageCol.column.config_errors,
      isRollup: !!pageCol.column.rollup_config?.sourceColId,
      writeRollupConfig: pageCol.column.write_rollup_config,
      syncedParentColId: pageCol.column.parent_col_id,
      isAdminColumn: !!pageCol.is_admin_column,
      columnFilters: pageCol.columnFilters ? mapApiColRuleToFilters(pageCol.columnFilters) : undefined,
      lookupFilters: pageCol.lookupFilters ? mapApiColRuleToFilters(pageCol.lookupFilters) : undefined,
      addFilters: pageCol.addFilters ? mapApiColRuleToFilters(pageCol.addFilters) : undefined,
      columnFiltersId: pageCol.columnFilters?.id,
      lookupFiltersId: pageCol.lookupFilters?.id,
      algoliaSearchTables: pageCol.algoliaSearchTables?.map((entry) => ({
        id: entry.id,
        algoliaSearchTableId: entry.algolia_search_table_id
      })),
      conditionalViewRules: pageCol.column.conditionalViewRules
        ? mapApiColumnRuleToPageColumnRule([pageCol.column.conditionalViewRules])?.[0]
        : undefined,
      sortOrder: pageCol.column.ui_pages_views_columns?.reduce((acc: any, viewCol) => {
        if (viewCol?.view?.pages_id === pageId && !viewCol?.view?.is_custom) {
          acc[viewCol?.view_type || ""] = viewCol.sort;
        }
        return acc;
      }, {}),
      views:
        pageCol.column.ui_pages_views_columns?.reduce((acc: any, viewCol) => {
          // View link should be to the right page
          // TODO: Resolve this with the right structure + query
          if (viewCol?.view?.pages_id === pageId && !viewCol?.view?.is_custom) {
            const view = pageViews?.find(
              (pageView) => pageView.view?.id === viewCol.view?.id && pageView.pages_id === pageId
            );
            acc[viewCol?.view_type || ""] = {
              id: viewCol.id,
              viewType: viewCol?.view_type,
              isHidden: !!viewCol?.is_hidden,
              view: viewCol.view?.views_id || {
                id: viewCol.views_id,
                view_type: viewCol?.view_type
              },
              sort: viewCol.sort || "",
              formRelationPageId: viewCol.form_relation_page_id,
              pinDirection: viewCol.pin_direction,
              hasDivider: !!viewCol.has_divider,
              additionalConfig: view?.view?.additional_config,
              isRequired: !!viewCol.is_required,
              isGroupBy: !!viewCol.is_group_by,
              isGroupBySecond: !!viewCol.is_group_by_second,
              columnSections: mapApiColSectionsToSections(viewCol.columnSections),
              isFeaturedImage: !!viewCol.is_featured_image
            };
          }
          return acc;
        }, {}) || {},
      customPageViews:
        pageCol.column.ui_pages_views_columns?.reduce((acc: any, viewCol) => {
          if (viewCol?.view?.pages_id === pageId && !!viewCol?.view?.is_custom) {
            const view = pageViews?.find(
              (view) => view.is_custom && view.view.id === viewCol.views_id && view.pages_id === pageId
            );
            const viewColInput = {
              id: viewCol.id,
              viewType: viewCol?.view_type,
              isHidden: !!viewCol?.is_hidden,
              view: {
                id: viewCol.view?.views_id?.id,
                viewType: viewCol?.view_type,
                pageViewId: viewCol.view?.id
              },
              sort: viewCol.sort || "",
              formRelationPageId: viewCol.form_relation_page_id,
              pinDirection: viewCol.pin_direction,
              hasDivider: !!viewCol.has_divider,
              additionalConfig: view?.view?.additional_config,
              isRequired: !!viewCol.is_required,
              isGroupBy: !!viewCol.is_group_by,
              isGroupBySecond: !!viewCol.is_group_by_second,
              columnSections: mapApiColSectionsToSections(viewCol.columnSections),
              isFeaturedImage: !!viewCol.is_featured_image
            };
            acc[viewCol.view?.views_id?.id || ""] = viewColInput;
          }
          return acc;
        }, {}) || {}
    };
  });
  return convertedCols.sort((a, b) => {
    return (a.createdAt || 0) > (b.createdAt || 0) ? 1 : -1;
  }) as TableColumnType[];
};

export const mapApiViewColumnToTableViewColumn = (apiViewColumn: ApiPageViewColumn): TablePageViewColumn => {
  return {
    id: apiViewColumn.id,
    viewsId: apiViewColumn.views_id,
    columnsId: apiViewColumn.columns_id,
    formRelationPageId: apiViewColumn.form_relation_page_id,
    isHidden: !!apiViewColumn.is_hidden,
    isRequired: !!apiViewColumn.is_required,
    isFeaturedImage: !!apiViewColumn.is_featured_image,
    isGroupBy: !!apiViewColumn.is_group_by,
    isGroupBySecond: !!apiViewColumn.is_group_by_second,
    hasDivider: !!apiViewColumn.has_divider,
    sort: apiViewColumn.sort,
    columnSections: mapApiColSectionsToSections(apiViewColumn.columnSections),
    view: apiViewColumn?.view?.views_id || {
      id: apiViewColumn.view?.views_id?.id || "",
      view_type: apiViewColumn.view_type,
      isPageViewCustom: !!apiViewColumn?.view?.is_custom
    }
  };
};

export const mapApiPageViewsToPageViews = (apiViews: ApiPageViews[]): TableViewConfig[] => {
  return apiViews.map((apiView) => {
    return {
      id: apiView.id, // id from ui_pages_views
      viewType: apiView.view?.view_type,
      viewId: apiView.view?.id,
      isActive: !!apiView.view?.is_active,
      multiselectEnabled: !!apiView.view?.multiselect_enabled,
      disableExpand: !!apiView.view?.disable_expand,
      displayWithIcons: !!apiView.view?.display_with_icons,
      requiredColumns: apiView.columns?.filter((col) => !!col.is_required).map((col) => col.columns_id) || [],
      columns: apiView.columns?.map((col) => mapApiViewColumnToTableViewColumn(col)) || [],
      tabs: apiView.tabs?.length ? mapApiPageViewTabsToPageViewTabs(apiView.tabs) : [],
      columnColors: apiView.view?.column_colors,
      galleryTabId: apiView.view?.gallery_tab_id,
      defaultPageTab: apiView.view?.default_page_tab,
      additionalConfig: apiView.view?.additional_config,
      subType: apiView.view?.sub_type,
      isDefault: !!apiView.view?.is_default,
      isCustom: !!apiView.view?.is_custom,
      isPageViewCustom: !!apiView.is_custom,
      title: apiView.view?.title,
      defaultColumnSectionId: apiView.default_column_section_id,
      defaultColumnSection: apiView?.defaultColumnSection
        ? mapApiColSectionsToSections([
            { id: apiView.defaultColumnSection.id, section: apiView.defaultColumnSection }
          ])?.[0]
        : undefined,
      sections: apiView?.sections
        ? mapApiColSectionsToSections(apiView?.sections.map((section) => ({ id: section.id, section })))
        : undefined
    };
  });
};

export const mapApiPageViewTabsToPageViewTabs = (apiTabs: ApiPageViewTabs[]): TablePageViewTab[] => {
  return apiTabs.map((apiTab) => {
    return {
      id: apiTab.id,
      tabId: apiTab.tab?.id || apiTab.ui_tab_id,
      page:
        typeof apiTab.tab?.tab_page_id === "string" || typeof apiTab.tab?.tab_page_id === "number"
          ? { id: (apiTab.tab?.tab_page_id as string) || "", path: "", title: "", table_name: "" }
          : apiTab.tab?.tab_page_id,
      defaultView: apiTab.tab?.default_view,
      sidebarDefaultView: apiTab.tab?.sidebar_default_view,
      tableName: apiTab.tab?.tab_page_id?.table_name || "",
      filterRelations: apiTab.tab?.filter_relations,
      title: apiTab.tab?.title || "",
      icon: apiTab.tab?.icon,
      hasAdd: !!apiTab.tab?.add_page_id,
      addPage:
        typeof apiTab.tab?.add_page_id === "string" || typeof apiTab.tab?.add_page_id === "number"
          ? { id: (apiTab.tab?.add_page_id as string) || "", path: "", title: "", table_name: "" }
          : apiTab.tab?.add_page_id,
      addPageFilters: apiTab.tab?.addPageFilters ? mapApiColRuleToFilters(apiTab.tab.addPageFilters) : undefined,
      addPageFilterId: apiTab.tab?.addPageFilters?.id,
      addTableName: apiTab.tab?.add_page_id?.table_name,
      recordIdSource: apiTab.tab?.recordid_source,
      sort: apiTab.tab?.sort,
      visibility: apiTab.tab?.visibility,
      uiMenuItemId: apiTab.tab?.ui_menu_item_id,
      createdAt: apiTab.created_at,
      defaultAddExpanded: !!apiTab.tab?.default_add_expanded,
      showRecordCount: !!apiTab.tab?.show_record_count,
      isHidden: !!apiTab.is_hidden,
      isChild: !!apiTab.is_child
    };
  });
};

export const mapApiPageToPage = (apiPage: ApiPage): Page => {
  if (!apiPage) return {} as Page;
  return {
    ...apiPage,
    isDefaultTablePage: !!apiPage.is_default_table_page,
    ui_pages_actions: mapApiPageActionsToPageActions(apiPage.ui_pages_actions || []),
    columns: apiPage.columns?.length
      ? mapPageColumnsToType(apiPage.columns || [], apiPage.views || [], apiPage.id)
      : [],
    views: apiPage.views?.length ? mapApiPageViewsToPageViews(apiPage.views || []) : [],
    pageFilters: apiPage?.pageFilters?.columnRules?.length
      ? { id: apiPage.pageFilters?.id, filters: mapApiColRuleToFilters(apiPage.pageFilters) }
      : undefined,
    conditionalFormatting: apiPage.conditionalFormatting?.length
      ? mapApiColumnRuleToPageColumnRule(apiPage.conditionalFormatting)
      : undefined,
    algoliaSearchTableId: apiPage.algolia_search_table_id
  } as Page;
};

export const mapApiViewToTableViewConfig = (apiView: ApiView, isCustomView?: boolean): TableViewConfig => {
  if (!apiView) return {} as TableViewConfig;
  const allColumns = apiView.columns?.map((viewCol) => {
    return {
      column: viewCol.column,
      ...viewCol.pageColumn,
      viewColumnId: viewCol.id,
      lookupFilters: apiView.additional_config?.isStaticCustomForm
        ? viewCol.lookupFilters
        : viewCol.pageColumn?.lookupFilters,
      columnFilters: apiView.additional_config?.isStaticCustomForm
        ? viewCol.columnFilters
        : viewCol.pageColumn?.columnFilters
    };
  }) as ApiPageColumn[];

  return {
    ...apiView,
    viewType: apiView.view_type,
    additionalConfig: apiView.additional_config,
    viewColumns: allColumns?.length ? mapPageColumnsToType(allColumns, [], "", isCustomView) : [],
    sections: apiView?.sections
      ? mapApiColSectionsToSections(apiView?.sections.map((section) => ({ id: section.id, section })))
      : undefined
  } as TableViewConfig;
};

export const mapApiPageActionsToPageActions = (actions: ApiPageAction[]): PageAction[] => {
  return actions.map((action) => ({
    ...action,
    viewId: action.ui_views_id,
    view: action.view ? mapApiViewToTableViewConfig(action.view) : action.view,
    conditionalViewRules: action.conditionalViewRules
      ? mapApiColumnRuleToPageColumnRule([action.conditionalViewRules])?.[0]
      : undefined
  }));
};

export const mapApiAlgoliaSearchTableToAlgoliaSearchTable = (apiSearchTable: ApiAlgoliaSearchTable) => {
  if (!apiSearchTable) return {} as AlgoliaSearchTable;
  return {
    id: apiSearchTable.id,
    title: apiSearchTable.title,
    isActive: apiSearchTable.is_active,
    createdAt: apiSearchTable.created_at,
    updatedAt: apiSearchTable.updated_at,
    createdBy: apiSearchTable.created_by,
    updatedBy: apiSearchTable.updated_by,
    indexName: apiSearchTable.index_name,
    tableName: apiSearchTable.table_name,
    resultConfig: apiSearchTable.result_config,
    hasCreateAction: apiSearchTable.has_create_action,
    sortOrder: apiSearchTable.sort_order,
    enableMasterIndex: apiSearchTable.enable_master_index,
    masterIndexConfig: apiSearchTable.master_index_config,
    isReservedIndex: apiSearchTable.is_reserved_index,
    additionalConfig: apiSearchTable.additional_config,
    syncError: apiSearchTable.sync_error,
    linkedPage: apiSearchTable.linkedPage,
    organizationId: apiSearchTable.org_id,
    columns:
      apiSearchTable.algoliaColumns?.map((algCol) => {
        const pageCol = algCol.pageColumn;
        return {
          id: pageCol.column.id,
          name: pageCol.column.name,
          header: pageCol.column.header,
          createdAt: pageCol.column.created_at,
          type: pageCol.column.type,
          pageColumnId: pageCol.id,
          pageId: pageCol.page_id?.id || pageCol.page_id,
          hasFilter: !!pageCol.column.has_filter,
          filterSortOrder: pageCol.column.filter_sort_order,
          hasBulkEdit: !!pageCol.column.has_bulk_edit,
          cellConfig: pageCol.column.cell_config,
          isLookup: !!pageCol.column.is_lookup,
          isFormula: !!pageCol.column.is_formula,
          lookupPath: pageCol.column.lookup_path,
          isMultiple: !!pageCol.column.is_multiple,
          isEditable: !!pageCol.column.is_editable,
          isSelect: !!pageCol.column.is_select,
          dbType: pageCol.column.db_type,
          defaultValues: pageCol.column.default_values,
          formula: pageCol.column.formula,
          isRollup: !!pageCol.column.rollup_config?.sourceColId,
          writeRollupConfig: pageCol.column.write_rollup_config,
          lookupFilters: pageCol.lookupFilters ? mapApiColRuleToFilters(pageCol.lookupFilters) : undefined,
          lookupFiltersId: pageCol.lookupFilters?.id
        };
      }) || []
  };
};

export const mapApiMenuItemToMenuItem = (apiMenuItem: ApiMenuItem) => {
  return {
    ...apiMenuItem,
    additionalConfig: apiMenuItem.additional_config
  } as MenuItem;
};

export const getPageFiltersFromTab = ({
  recordPage,
  currentTabVal,
  recordData,
  isParentPublicId,
  recordId,
  byPassFileListIdCreation,
  currentProjectId,
  tabPageData,
  currentRecordId,
  isRecordPageTab
}: {
  recordPage: Page;
  currentTabVal?: TablePageViewTab;
  recordData?: RecordItem;
  isParentPublicId?: boolean;
  isRecordPageTab?: boolean;
  recordId?: string;
  byPassFileListIdCreation?: boolean;
  currentProjectId?: string;
  tabPageData?: Page;
  currentRecordId?: string;
}) => {
  let finalFilters: any = {};
  let nestedTableFilter = "";
  let finalFiltersList = [];
  let disableFilesListFetch = false;

  const isTabRecordTable = currentTabVal?.tableName === recordPage.table_name;
  if (currentTabVal?.filterRelations) {
    let finalFilterValue = isParentPublicId ? recordData?.id : recordId;
    if (currentTabVal?.recordIdSource?.columnId && recordData) {
      finalFilterValue = getPropertyFromRecordSource({
        columns: recordPage?.columns || [],
        record: recordData,
        recordIdSource: currentTabVal?.recordIdSource
      });
    }
    const filterRelationKeys = Object.keys(currentTabVal.filterRelations);
    filterRelationKeys.forEach((key: string) => {
      const filterField = currentTabVal.filterRelations?.[key];
      const islookupForeignKey = filterField?.lookupType === LookupTypes.FOREIGN;
      let finalFilterField = filterField?.lookupColumn;
      // In some filter relation, notes list id is selected from the parent and stores as a foreign key
      if (islookupForeignKey && !(tabPageData?.table_name === "notes" && finalFilterField === "notes_list_id")) {
        finalFilterField = filterField?.lookupForeignKey || filterField?.lookupColumn || "";
      } else if (filterField?.lookupTableName) {
        nestedTableFilter += isTabRecordTable
          ? filterField.lookupColumn
          : filterField.lookupTableName + "." + filterField.lookupColumn;
      }
      if (filterField?.lookupColumn) {
        const isFilesListsFilesFilter = filterField?.lookupTableName === "files_lists_files";
        if (
          filterRelationKeys?.length === 1 &&
          !islookupForeignKey &&
          !isTabRecordTable &&
          filterField.lookupTableName
        ) {
          finalFilterField = filterField.lookupTableName + "." + filterField.lookupColumn;
        }
        const filterLookupPath = isFilesListsFilesFilter
          ? {
              "0": {
                ...currentTabVal?.filterRelations?.["0"],
                lookupColumns: [currentTabVal?.filterRelations?.["0"]?.lookupColumn]
              }
            }
          : undefined;
        if (!byPassFileListIdCreation && isFilesListsFilesFilter && !finalFilterValue && recordData?.id) {
          disableFilesListFetch = true;
        }
        if (!byPassFileListIdCreation && isFilesListsFilesFilter && finalFilterValue) {
          disableFilesListFetch = false;
        }
        finalFilters = {
          filterField: finalFilterField,
          filterValue: finalFilterValue,
          filterOperator: "equals",
          filterLookupPath
        };
      } else {
        nestedTableFilter += nestedTableFilter?.length
          ? `(${filterField?.lookupTableName || ""}`
          : filterField?.lookupTableName || "";
      }
    });

    // Check if this is Notes tab for a project
    // we switch to OR filter with notes list ID and project id
    // ##HARDCODED
    if (
      recordPage?.table_name === "projects" &&
      tabPageData?.table_name === "notes" &&
      finalFilters?.filterField === "notes_list_id" &&
      finalFilters?.filterValue &&
      currentProjectId
    ) {
      const updatedFinalFilters = {
        filterField: "or_group",
        filterOperator: FILTER_OPERATOR.OR,
        filterValue: finalFilters?.filterValue,
        filterGroup: [
          {
            ...finalFilters
          },
          {
            filterField: "project_id",
            filterValue: currentProjectId,
            filterOperator: "equals"
          }
        ]
      };
      finalFilters = updatedFinalFilters;
    }

    if (finalFilters?.filterField) {
      finalFiltersList = [finalFilters];
      const orFilterFields: string[] = [];
      // Check if tab page has filters
      if (tabPageData?.pageFilters?.filters?.length) {
        // Check for special defaults in filters
        const finalTabFilters = tabPageData?.pageFilters?.filters?.map((filter) => {
          if (filter.filterOperator === FILTER_OPERATOR.CURRENT_PROJECT && currentProjectId) {
            return {
              ...filter,
              filterValue: currentProjectId
            };
          }
          if (
            filter.filterOperator === FILTER_OPERATOR.CURRENT_RECORD &&
            (currentRecordId || (isRecordPageTab && recordId))
          ) {
            return {
              ...filter,
              filterValue: currentRecordId || recordId
            };
          }
          if (filter.filterOperator === FILTER_OPERATOR.OR && filter.filterGroup?.length) {
            return {
              ...filter,
              filterGroup: filter.filterGroup.map((groupFilter) => {
                orFilterFields.push(groupFilter.filterField || "");
                if (groupFilter.filterOperator === FILTER_OPERATOR.CURRENT_PROJECT && currentProjectId) {
                  return {
                    ...groupFilter,
                    filterValue: currentProjectId
                  };
                }
                if (groupFilter.filterOperator === FILTER_OPERATOR.CURRENT_RECORD && currentRecordId) {
                  return {
                    ...groupFilter,
                    filterValue: currentRecordId
                  };
                }
                return groupFilter;
              })
            };
          }
          return filter;
        });

        finalFiltersList = [...finalTabFilters, finalFilters];
        // Remove AND filter for a column if it is present in the OR filter group
        if (orFilterFields?.length) {
          finalFiltersList = finalFiltersList.filter((filter) => {
            if (orFilterFields.includes(filter.filterField) && filter.filterOperator !== FILTER_OPERATOR.OR) {
              return null;
            }
            return true;
          });
        }
      }
    }
  }
  return {
    finalFiltersList,
    disableFilesListFetch
  };
};

export const mapApiUserHistoryFieldsToUserHistory = (userHistory: ApiUserHistory): UserHistory => {
  return {
    id: userHistory.id,
    peopleId: userHistory.people_id,
    pageTitle: userHistory.page_title,
    pagePath: userHistory.page_path,
    additionalFields: userHistory.additional_fields
  };
};

import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import { TableColumnType, TableFilterType } from "types/baTypes";
import { RecordItem, SelectOption, SelectOptions } from "types/common";
import {
  BOOLEAN_OPTION,
  CellType,
  DATE_FILTER_OPTIONS,
  FILTER_BY_COLUMN_TYPES,
  FILTER_OPERATOR,
  FILTER_OPERATOR_FOR_SPECIAL_CELLS,
  FILTER_OPERATOR_NEED_VALUE,
  FILTER_OPERATOR_OPTIONS,
  NUMERIC_FILTER_OPTIONS,
  TEXT_FILTER_OPTIONS
} from "utils/constants";
import { isColumnFileTag, isColumnNestedFileTag, isColumnTagsJoinTable } from "utils/dataUtils";
import { ExtendedSchema } from "utils/schema";

// Rules for allow certain filter operators depending on column type
// or column lookupDBType, or dbType

const BooleanOptions = [BOOLEAN_OPTION];

const PhoneOptions = TEXT_FILTER_OPTIONS.filter((opt) =>
  [FILTER_OPERATOR.EQUALS, FILTER_OPERATOR.NOT_EQUALS, FILTER_OPERATOR.EMPTY, FILTER_OPERATOR.NOT_EMPTY].includes(
    opt.value
  )
);

const FilterOptionsDefault = FILTER_OPERATOR_OPTIONS.filter(
  (opt) => !opt.isAdminOnly && ![FILTER_OPERATOR.CONTAINS, FILTER_OPERATOR.DOES_NOT_CONTAIN].includes(opt.value)
);

export const getValidFilterTypes = (column: TableColumnType) => {
  const isColumnMultiSelect = !!column.isMultiple;
  const isColWithOptions = !!column.isLookup || !!column.isSelect;
  let finalFilterOptions = FILTER_OPERATOR_OPTIONS.filter((opt) => !opt.isAdminOnly);
  if (
    ([CellType.TEXT, CellType.EMAIL].includes(column.type) ||
      (column.dbType?.format === "text" && column?.type !== CellType.PHONE)) &&
    column.dbType?.format !== "integer"
  ) {
    finalFilterOptions = TEXT_FILTER_OPTIONS;
  } else if (
    column?.dbType?.format === "numeric" ||
    (column.dbType?.format === "integer" && column.type === CellType.TEXT)
  ) {
    finalFilterOptions = NUMERIC_FILTER_OPTIONS;
  } else if (column?.dbType?.format === "datetime") {
    finalFilterOptions = DATE_FILTER_OPTIONS;
  } else if (column?.dbType?.format === "boolean" || column.type === CellType.BOOLEAN) {
    finalFilterOptions = BooleanOptions;
  } else if (column?.type === CellType.PHONE) {
    finalFilterOptions = PhoneOptions;
  } else if (column?.dbType?.format === "jsonb") {
    finalFilterOptions = FILTER_OPERATOR_OPTIONS.filter((opt) =>
      [FILTER_OPERATOR.EMPTY, FILTER_OPERATOR.NOT_EMPTY, FILTER_OPERATOR.CONTAINS, FILTER_OPERATOR.EQUALS].includes(
        opt.value
      )
    );
  } else if (
    [
      CellType.PEOPLE,
      CellType.PROJECT,
      CellType.SPACES,
      CellType.ADDRESS,
      CellType.BADGE,
      CellType.STATUS,
      CellType.STATE,
      CellType.COMPANY
    ].includes(column.type)
  ) {
    finalFilterOptions = FILTER_OPERATOR_FOR_SPECIAL_CELLS.filter(
      (opt) => (isColumnMultiSelect && opt.value == FILTER_OPERATOR.IN) || opt.value !== FILTER_OPERATOR.IN
    );
  } else {
    finalFilterOptions = FilterOptionsDefault;
  }
  if (isColumnMultiSelect) {
    finalFilterOptions = finalFilterOptions.filter(
      (opt) =>
        ![
          FILTER_OPERATOR.EQUALS,
          FILTER_OPERATOR.NOT_EQUALS,
          FILTER_OPERATOR.GREATER_THAN,
          FILTER_OPERATOR.GREATER_THAN_EQUALS,
          FILTER_OPERATOR.LESS_THAN,
          FILTER_OPERATOR.LESS_THAN_EQUALS
        ].includes(opt.value)
    );
  }

  if (isColWithOptions) {
    finalFilterOptions = finalFilterOptions.filter(
      (opt) => ![FILTER_OPERATOR.CONTAINS, FILTER_OPERATOR.DOES_NOT_CONTAIN].includes(opt.value)
    );
  }

  return finalFilterOptions;
};

export const getCountValidFilters = (filters: TableFilterType[]) => {
  const count = filters.reduce((acc, filter) => {
    const notNeedValue = !FILTER_OPERATOR_NEED_VALUE.includes(filter?.filterOperator as FILTER_OPERATOR);
    const isBooleanType = filter.type === CellType.BOOLEAN;
    const hasValue =
      FILTER_OPERATOR_NEED_VALUE.includes(filter?.filterOperator as FILTER_OPERATOR) && !!filter.filterValue;

    if (notNeedValue || isBooleanType || hasValue) {
      return acc + 1;
    }

    return acc;
  }, 0);

  return count;
};

export const CURRENT_USER_FILTER_ID = "cuf";

export type TableColumnTypeAndFilters = TableColumnType & {
  filterOperator?: string;
  filterValue?: string | SelectOption | boolean;
  filterGroup?: TableFilterType[];
};
export const generateFilterFromColumn = ({
  column,
  extendedSchema,
  operator
}: {
  column: TableColumnTypeAndFilters;
  extendedSchema?: ExtendedSchema;
  operator: string;
}): TableFilterType => {
  let isFilterColumnJoinTable = false;
  let joinTableFilterColumn = "";
  let isTagsJoinTable = false;
  let useFilterLookupColumn = false;
  let isFileTagNestedColumn = false;
  let useColumnLabel = false;
  if (column?.isLookup) {
    const tableName = column?.lookupPath?.[0]?.lookupTableName;
    if (tableName && extendedSchema?.[tableName]?.compositePk?.length) {
      isFilterColumnJoinTable = true;
    }
    isTagsJoinTable = isColumnTagsJoinTable(column, extendedSchema);
    // ##HARDCODED Special case for file_tags table
    if (isColumnFileTag(column)) {
      joinTableFilterColumn = "tag_id";
    }
    // Check if column type is in FILTER_BY_COLUMN_TYPES if so we should compare lookup column instead of id
    // Second condition is for lookup column, which has Select options and is of type Text
    // This may be expanded to include other conditions in the future
    if (
      FILTER_BY_COLUMN_TYPES.includes(column?.type) ||
      (column?.type === CellType.TEXT && column?.isSelect && column?.isLookup)
    ) {
      useFilterLookupColumn = true;
    }
    isFileTagNestedColumn = isColumnNestedFileTag(column);
    if (isFileTagNestedColumn) {
      useColumnLabel = true;
    }
  }
  return {
    ...column,
    column: { ...column },
    filterField: column.name,
    filterOperator: operator,
    isFilterColumnJoinTable,
    joinTableFilterColumn,
    isTagsJoinTable,
    useFilterLookupColumn,
    innerAtAllLevels:
      !!isFileTagNestedColumn || column.type === CellType.PEOPLE || isTagsJoinTable || isFilterColumnJoinTable,
    useColumnLabel: useColumnLabel || column.type === CellType.PEOPLE || isTagsJoinTable
  };
};

export const getFilterValueFromFilter = (filter?: TableFilterType, column?: TableColumnType) => {
  if (column?.type === CellType.BOOLEAN && (filter?.filterValue === undefined || filter?.filterValue === "")) {
    return false;
  } else if (Array.isArray(filter?.filterValue)) {
    return (filter?.filterValue as RecordItem[]).map((item) => item.record);
  } else if (isObject(filter?.filterValue)) {
    return (filter?.filterValue as RecordItem)?.record;
  }
  return filter?.filterValue;
};

export const evaluateIfIsFilterTextSearch = (filter: TableFilterType, colId: string) => {
  return (
    filter.id === colId &&
    filter.column &&
    !!filter.column?.name &&
    filter.column?.dbType?.format === "text" &&
    !(
      filter.column.type === CellType.SELECT &&
      (!isEmpty(filter.column.columnOptionsLookUp) || !isEmpty(filter.column.lookupPath))
    ) &&
    (filter.filterOperator === FILTER_OPERATOR.EQUALS || !filter.filterOperator)
  );
};

export const shouldResetFilterValueOnOperatorChange = (
  prevOperator?: FILTER_OPERATOR,
  newOperator?: FILTER_OPERATOR
) => {
  if (!prevOperator || !newOperator) return true;

  const multipleValueOperators = [FILTER_OPERATOR.IN, FILTER_OPERATOR.NOT_IN];
  const emptyValueOperators = [FILTER_OPERATOR.EMPTY, FILTER_OPERATOR.NOT_EMPTY];
  const relationalOperators = [
    FILTER_OPERATOR.EQUALS,
    FILTER_OPERATOR.NOT_EQUALS,
    FILTER_OPERATOR.GREATER_THAN,
    FILTER_OPERATOR.LESS_THAN,
    FILTER_OPERATOR.GREATER_THAN_EQUALS,
    FILTER_OPERATOR.LESS_THAN_EQUALS
  ];
  const booleanOperators = [FILTER_OPERATOR.CONTAINS, FILTER_OPERATOR.DOES_NOT_CONTAIN];

  // if both operators belongs to same group, don't reset the value on operator change
  if (
    (multipleValueOperators.includes(prevOperator) && multipleValueOperators.includes(newOperator)) ||
    (emptyValueOperators.includes(prevOperator) && emptyValueOperators.includes(newOperator)) ||
    (relationalOperators.includes(prevOperator) && relationalOperators.includes(newOperator)) ||
    (booleanOperators.includes(prevOperator) && booleanOperators.includes(newOperator))
  ) {
    return false;
  }

  return true;
};

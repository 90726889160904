import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import mathEvaluator from "math-expression-evaluator";
import isObject from "lodash/isObject";

dayjs.extend(utc);
dayjs.extend(timezone);

import { COLUMN_CONDITIONAL_VISIBILITY } from "components/Admin/utils";
import { testRule } from "components/DataGridView/utils";
import { isBasicColumn } from "components/Admin/utils";
import { getColumnValueFromRowData, getTextFromColumnWithFieldSelected } from "lib/utils";
import { ApiRecordType } from "types/apiTypes";
import { ColumnConditionalRule, ColumnRule, Page, TableColumnType } from "types/baTypes";
import { COLUMN_CONFIG_ERROR_TYPES, RecordItem, SelectOption } from "types/common";
import {
  CellType,
  FILTER_GROUP_TYPE,
  LookupTypes,
  SPECIAL_DEFAULTS,
  SPECIAL_DEFAULTS_VALUES,
  SelectCellTypes,
  ViewOption
} from "./constants";
import { parseFormulaToValidExpression } from "./dataUtils";
import { ExtendedSchema, TableSchema } from "./schema";

export const getTimeStamp = () => {
  const dayjsLocal = dayjs();
  const dayjsLA = dayjsLocal.tz("America/Los_Angeles");
  return dayjsLA.format();
};

// Column is set up to fetch options but is of type text in the DB
export const isColumnTextWithOptions = (column: TableColumnType) =>
  ((SelectCellTypes.includes(column.type) || column.isSelect) &&
    column.name &&
    (column.dbType?.format === "text" || column.dbType?.format === "bigint")) ||
  (column.isLookup && column.type === CellType.TEXT);

// Current requirements for column to fit this criteria:
// Column is a lookup
// Column should only have a single lookup column on a join table
// this is the column that will be updated
// Does not check if table is join or not, only column

export const isColumnForTextFieldOnJoinTable = (column: TableColumnType) => {
  if (!column || !column.isLookup) return false;
  const { lookupPath } = column;
  const firstLookupLevel = lookupPath?.[0];
  if (!firstLookupLevel || !firstLookupLevel?.lookupColumns?.length) return false;
  const { lookupColumns, lookupDBType } = firstLookupLevel;
  return (
    lookupDBType?.[lookupColumns[0]]?.format === "text" ||
    lookupDBType?.[lookupColumns[0]]?.format === "boolean" ||
    lookupDBType?.[lookupColumns[0]]?.format === "number"
  );
};

export const isTableJoin = (tableName: string, extendedSchema: { [tableName: string]: TableSchema }) => {
  if (!extendedSchema?.[tableName]) return false;

  const tableSchema = extendedSchema[tableName];
  return !!tableSchema?.compositePk?.length;
};

export const getFormulaResultForColumn = ({
  formulaColumn,
  allColumns,
  record,
  isForm
}: {
  formulaColumn: TableColumnType;
  allColumns: TableColumnType[];
  record?: RecordItem;
  isForm: boolean;
}) => {
  const { formula } = formulaColumn;
  if (!formula) return null;
  let finalFormula = isForm ? formula : parseFormulaToValidExpression(allColumns, formula);
  // use percent value for percent fields while multiplying
  const parseToPercent = finalFormula.includes("*");

  const percentFields = parseToPercent
    ? allColumns.filter((col) => col.type === CellType.PERCENT).map((col) => (isForm ? col.id : col.name))
    : [];

  Object.entries(record || {}).forEach(([name, value]) => {
    const divider = percentFields.includes(name) ? 100 : 1;
    const finalVal =
      typeof value === "string"
        ? value.includes(".")
          ? parseFloat(parseFloat(value).toFixed(3))
          : parseInt(value)
        : value;
    const num = (finalVal / divider).toString();

    finalFormula = finalFormula.replaceAll(`{${name}}`, num);
  });
  if (finalFormula.includes("NaN")) return null;

  return mathEvaluator.eval(finalFormula);
};

export const getFormulaResultForColumnForForm = ({
  formulaColumn,
  allColumns,
  record,
  isForm
}: {
  formulaColumn: TableColumnType;
  allColumns: TableColumnType[];
  record?: RecordItem;
  isForm: boolean;
}) => {
  const { formula } = formulaColumn;
  if (!formula) return null;
  let finalFormula = isForm ? formula : parseFormulaToValidExpression(allColumns, formula);
  // use percent value for percent fields while multiplying
  const parseToPercent = finalFormula.includes("*");

  const percentFields = parseToPercent
    ? allColumns.filter((col) => col.type === CellType.PERCENT).map((col) => (isForm ? col.id : col.name))
    : [];
  // Find all colIds in formula
  const colIds = finalFormula.match(/{(.*?)}/g)?.map((colId) => colId.replace(/{|}/g, "")) || [];
  if (colIds.length) {
    colIds.forEach((colId) => {
      const divider = percentFields.includes(colId) ? 100 : 1;
      const recordValue = record?.[colId];
      const finalVal =
        typeof recordValue === "string"
          ? recordValue.includes(".")
            ? parseFloat(parseFloat(recordValue).toFixed(3))
            : parseInt(recordValue)
          : recordValue;
      const num = (finalVal / divider).toString();
      finalFormula = !recordValue ? "NaN" : finalFormula.replaceAll(`{${colId}}`, num);
    });
  }

  if (finalFormula.includes("NaN")) return null;

  return mathEvaluator.eval(finalFormula);
};

// Column is lookup path with two levels and second level is foreign key on first
// But is not a composite primary key
export const isLookupForeignKeyOnJoinTable = (
  column: TableColumnType,
  extendedSchema?: { [key: string]: TableSchema }
) => {
  if (!column.isLookup) return false;
  const { lookupPath } = column;
  const tableProps = extendedSchema?.[lookupPath?.["0"]?.lookupTableName || ""];

  if (
    lookupPath?.[0]?.lookupType === LookupTypes.JOIN &&
    lookupPath?.["1"]?.lookupType === LookupTypes.FOREIGN &&
    !tableProps?.compositePk?.find((pk) => pk.table === lookupPath?.["1"]?.lookupTableName) &&
    !lookupPath?.["2"]?.lookupTableName
  ) {
    return true;
  }
  return false;
};

export const getVisibilityByColumnRule = (
  ruleCondition: ColumnConditionalRule,
  rule: ColumnRule,
  colToEvaluate?: TableColumnType,
  value?: RecordItem,
  view?: ViewOption
) => {
  if (!colToEvaluate || !ruleCondition) return;
  const valueToEvaluate = getTextFromColumnWithFieldSelected(
    colToEvaluate,
    value,
    view === ViewOption.FORM ? ruleCondition?.field : ruleCondition?.lookupColumnNameField
  );
  const passRule = testRule(valueToEvaluate, ruleCondition);

  if (passRule) {
    return rule.ruleConfig?.visibility;
  }
  return;
};

export const getFinalVisibilityFromColumnRule = ({
  columnRule,
  checkRulePassed
}: {
  columnRule?: ColumnRule;
  checkRulePassed: (rule: ColumnConditionalRule) => boolean;
}) => {
  const isAllRulesPassed = columnRule?.columnRules?.reduce((acc: boolean, colRule: ColumnConditionalRule, index) => {
    const isPassed = checkRulePassed(colRule);
    if (index === 0) {
      // first rule
      return isPassed;
    }
    if (colRule.filterGroup === FILTER_GROUP_TYPE.OR) {
      return acc || isPassed;
    } else {
      return acc && isPassed;
    }
  }, false);
  const isHidden =
    columnRule?.ruleConfig?.visibility === COLUMN_CONDITIONAL_VISIBILITY.HIDE ? isAllRulesPassed : !isAllRulesPassed;

  return isHidden ? COLUMN_CONDITIONAL_VISIBILITY.HIDE : COLUMN_CONDITIONAL_VISIBILITY.SHOW;
};

export const isColumnInView = (column: TableColumnType, viewType: ViewOption) => {
  return !!column.views?.[viewType]?.id || !!column.views?.[viewType]?.isHidden;
};

export const isColumnDisplayInView = (column: TableColumnType, viewType: ViewOption) => {
  return !!column.views?.[viewType]?.id && !column.views?.[viewType]?.isHidden;
};

// This utility checks if the column config is valid
// If not valid it returns the error message
export const checkColumnValidity = ({
  column,
  recordTypesData,
  page,
  includeColDetails,
  extendedSchema
}: {
  column: TableColumnType;
  recordTypesData?: ApiRecordType[];
  page: Page;
  includeColDetails?: boolean;
  extendedSchema?: ExtendedSchema;
}) => {
  if (!column) {
    return {
      isColValid: false,
      message: "Column not found",
      errorType: "Invalid Column"
    };
  }
  const pageId = page?.id;
  const pageTable = page?.table_name;
  const tableProps = extendedSchema?.[pageTable];
  if (column.isLookup) {
    const { lookupPath, lookupFilters, columnOptionsLookUp } = column;
    const lookupLevels = Object.keys(lookupPath || {});
    const matchingRecordType = recordTypesData?.find((recordType) => recordType.type === column.type);
    const firstLevelPath = lookupPath?.["0"];
    const colOptionsLookupLevels = Object.keys(columnOptionsLookUp || {});
    const colOptionsFirstLevelPath = columnOptionsLookUp?.["0"];
    if (
      matchingRecordType?.page_id &&
      (matchingRecordType?.lookup_column?.id || matchingRecordType?.image_column?.id) &&
      pageId !== matchingRecordType?.page_id &&
      matchingRecordType?.tablename !== pageTable
    ) {
      if (lookupLevels.length !== 1) {
        // There should only be one lookup level
        return {
          isValid: false,
          message: `${
            includeColDetails ? `${column.header}: ` : ""
          }Only one level of lookup is required for record types`,
          errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_RECORD_TYPE_COLUMN_OPTIONS
        };
      } else if (
        colOptionsLookupLevels?.length > 1 &&
        colOptionsFirstLevelPath?.lookupTableName === matchingRecordType?.tablename
      ) {
        return {
          isColValid: false,
          message: `${includeColDetails ? `${column.header}: ` : ""} Column options should be set to Use Record Type `,
          errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_RECORD_TYPE_COLUMN_OPTIONS
        };
      }
    }
    // Check if lookupPath has lookupColumns at the last level
    if (
      lookupLevels.length &&
      !(lookupPath || {})?.[lookupLevels[lookupLevels.length - 1]]?.lookupColumns?.length &&
      !matchingRecordType?.page_id
    ) {
      return {
        isColValid: false,
        message: `${includeColDetails ? `${column.header}: ` : ""}No lookup columns selected`,
        errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_COLUMNS
      };
    }

    if (!firstLevelPath?.lookupTableName) {
      return {
        isColValid: false,
        message: `${includeColDetails ? `${column.header}: ` : ""}Lookup path missing`,
        errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_PATH
      };
    }
    const firstLevelTableName = firstLevelPath?.lookupTableName;
    if (firstLevelPath?.lookupType === LookupTypes.FOREIGN) {
      // Check if lookupTableName is related to col
      const isForeignKey = tableProps?.fk.find((fkey) => fkey.table === firstLevelTableName);
      if (!isForeignKey) {
        return {
          isColValid: false,
          message: `${
            includeColDetails ? `${column.header}: ` : ""
          }Foreign key in lookup path is not related to the current table`,
          errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_PATH
        };
      }
    } else {
      // Check if lookup join table is related
      const lookupTableProps = extendedSchema?.[firstLevelTableName || ""];
      if (
        firstLevelTableName &&
        (!lookupTableProps || !lookupTableProps?.fk.find((fkey) => fkey.table === pageTable))
      ) {
        return {
          isColValid: false,
          message: `${
            includeColDetails ? `${column.header}: ` : ""
          }Lookup Table in lookup path is not related to the current table`,
          errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_PATH
        };
      }
      // Check if join table has multiple references to the same table
      // and no foreign key reference is set
      if (tableProps?.fk?.length) {
        const fkKeys: SelectOption[] = [];
        lookupTableProps?.fk?.forEach((item) => {
          if (item.table === pageTable) {
            fkKeys.push({ title: item.attributeId, value: item.attributeId });
          }
        });
        if (fkKeys.length > 1 && !firstLevelPath?.lookupTableHint) {
          return {
            isColValid: false,
            message: `${
              includeColDetails ? `${column.header}: ` : ""
            }Lookup Table needs a foreign key reference to the current table`,
            errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_JOIN_TABLE_FK_REF
          };
        }
      }
    }

    // Check all lookup levels for table hint value
    // And if all lookup tables are related
    const prevLevelTable = lookupPath?.["0"]?.lookupTableName;
    Object.keys(lookupPath || {}).forEach((level) => {
      const lookupDetails = lookupPath?.[level];
      if (!lookupDetails) return;
      if (lookupDetails.lookupType === LookupTypes.JOIN && lookupDetails.lookupTableName) {
        const prevLevel = level === "0" ? "0" : `${parseInt(level, 10) - 1}`;
        let parentLookupTableName = pageTable;
        if (level !== "0") {
          parentLookupTableName = lookupPath?.[prevLevel]?.lookupTableName || "";
        }
        const parentTableProps = extendedSchema?.[parentLookupTableName || ""];
        if (parentTableProps?.fk?.length) {
          const fkKeys: SelectOption[] = [];
          parentTableProps?.fk?.forEach((item) => {
            if (item.table === parentLookupTableName) {
              fkKeys.push({ title: item.attributeId, value: item.attributeId });
            }
          });
          if (fkKeys?.length > 1 && !lookupDetails.lookupTableHint) {
            return {
              isColValid: false,
              message:
                level !== "0"
                  ? `${
                      includeColDetails ? `${column.header}: ` : ""
                    }Lookup Table needs a foreign key reference in the lookup path`
                  : `${
                      includeColDetails ? `${column.header}: ` : ""
                    }Lookup Table needs a foreign key reference to the current table`,
              errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_JOIN_TABLE_FK_REF
            };
          }
        }
      }
      if (level !== "0" && prevLevelTable !== lookupDetails.lookupTableName) {
        const prevLevelTableProps = extendedSchema?.[prevLevelTable || ""];
        if (prevLevelTableProps) {
          if (lookupDetails?.lookupType === LookupTypes.FOREIGN) {
            if (!prevLevelTableProps?.fk.find((fkey) => fkey.attributeId === lookupDetails.lookupForeignKey)) {
              return {
                isColValid: false,
                message: `${
                  includeColDetails ? `${column.header}: ` : ""
                }Foreign key in lookup path is not related to the current table at level ${level}`,
                errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_PATH
              };
            }
          } else if (lookupDetails.lookupType === LookupTypes.JOIN) {
            if (
              !prevLevelTableProps?.joinTables?.find(
                (joinTable) => joinTable.tableName === lookupDetails.lookupTableName
              )
            ) {
              return {
                isColValid: false,
                message: `${
                  includeColDetails ? `${column.header}: ` : ""
                }Lookup Table in lookup path is not related to the current table at level ${level}`,
                errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_PATH
              };
            }
          }
        }
      }
    });

    // Check if lookupFilters are valid
    if (lookupFilters?.length) {
      const firstFilter = lookupFilters[0];
      if (firstFilter?.filterLookupPath) {
        const firstFilterPath = firstFilter.filterLookupPath["0"];
        if (firstFilterPath.lookupType === LookupTypes.FOREIGN) {
          const isForeignKey = tableProps?.fk.find((fkey) => fkey.table === firstFilterPath.lookupTableName);
          if (!isForeignKey) {
            return {
              isColValid: false,
              message: `${
                includeColDetails ? `${column.header}: ` : ""
              }Foreign key in lookup filters is not related to the current table`,
              errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_FILTERS
            };
          }
        } else {
          const lookupTableProps = extendedSchema?.[firstFilterPath.lookupTableName];
          if (!lookupTableProps || !lookupTableProps.fk.find((fkey) => fkey.table === pageTable)) {
            return {
              isColValid: false,
              message: `${
                includeColDetails ? `${column.header}: ` : ""
              }Lookup table in lookup filters is not related to the current table`,
              errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_FILTERS
            };
          }
        }
      }
      if (firstFilter?.filterField) {
        // Check if filterField is an existing column in the table
        const isColumn = tableProps?.attributeIds?.find((colName) => colName === firstFilter.filterField);
        if (!isColumn) {
          return {
            isColValid: false,
            message: `${
              includeColDetails ? `${column.header}: ` : ""
            }Filter field in lookup filters not found in the current table`,
            errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_LOOKUP_FILTERS
          };
        }
      }
    }
  }
  if (column.name && !column.isLookup && !column?.views?.[ViewOption.FORM]?.formRelationPageId) {
    const isColumn = tableProps?.attributeIds?.find((colName) => colName === column.name);
    if (!isColumn) {
      return {
        isColValid: false,
        message: `${includeColDetails ? `${column.header}: ` : ""}Column not found in the current table`,
        errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_COLUMN
      };
    }
  }

  // Cell type Color should have hex, rgb or color value
  if (column.type === CellType.COLOR) {
    if (column.isFormula) {
      return {
        isColValid: false,
        message: `${includeColDetails ? `${column.header}: ` : ""}Cell Color should be a lookup or basic column`,
        errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_COLUMN
      };
    }

    if (column.isLookup && column.lookupPath) {
      const lookupColumns = Object.keys(column.lookupPath).flatMap(
        (key) => column.lookupPath?.[key]?.lookupColumns || []
      );

      if (!lookupColumns.includes("hex") && !lookupColumns.includes("rgb") && !lookupColumns.includes("color")) {
        return {
          isColValid: false,
          message: `${
            includeColDetails ? `${column.header}: ` : ""
          }Lookup columns should have either 'hex' or 'rgb' or 'color'`,
          errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_COLUMN
        };
      }
    }
  }

  // Button/Generic Cell does not need a name selection
  if (
    !column.isLookup &&
    !column?.views?.[ViewOption.FORM]?.formRelationPageId &&
    !column.name &&
    column.type !== CellType.GENERIC_CELL &&
    column.type !== CellType.BEFORE_AFTER &&
    column.type !== CellType.BUTTON
  ) {
    return {
      isColValid: false,
      message: `${includeColDetails ? `${column.header}: ` : ""}Column needs to be selected`,
      errorType: COLUMN_CONFIG_ERROR_TYPES.INVALID_COLUMN
    };
  }
  return {
    isColValid: true,
    message: "",
    errorType: ""
  };
};
// This utility check if the column type has a matching record type page,
// if so it will append the lookup path and column options (if exists)
// from the record type page lookup column
// Image columns are handled by generateFinalDataColumns method in dataUtils
export const appendRecordTypeLookupToCol = (
  column: TableColumnType,
  recordTypes: ApiRecordType[],
  tableName: string,
  extendedSchema?: ExtendedSchema,
  skipCheck?: boolean
) => {
  if (!recordTypes?.length || !Object.keys(column.lookupPath || {}).length || !extendedSchema) return column;

  // Check if column is valid
  const { isColValid } = checkColumnValidity({
    column,
    recordTypesData: recordTypes,
    page: { table_name: tableName, id: "", path: "", title: "" },
    extendedSchema,
    includeColDetails: true
  });
  if (!isColValid && !skipCheck) {
    return null;
  }

  const matchingRecType = recordTypes?.find((recItem) => recItem.type === column.type);
  if (!matchingRecType?.page_id || matchingRecType?.tablename === tableName || column?.id?.startsWith("image_")) {
    return column;
  }
  const isFileProjectCol =
    column.type === CellType.PROJECT && column.lookupPath?.["0"]?.lookupTableName === "files_lists_files";
  const hasRecordBasicCols = Object.keys(matchingRecType?.config || {})?.length;
  const hasImageLookupCol = Object.keys(matchingRecType?.image_column?.lookup_path || {})?.length;
  // If relation is a join table, we need to add the foreign key to the lookup path before
  // adding the lookup column
  const isLevelZeroJoin = column.lookupPath?.["0"]?.lookupType === LookupTypes.JOIN;
  let levelZeroJoinForeignKey = null;
  const tableProps = isLevelZeroJoin ? extendedSchema?.[column.lookupPath?.[0]?.lookupTableName || ""] : null;
  if (tableProps?.compositePk?.length) {
    tableProps.compositePk.forEach((ck) => {
      if (ck.table === matchingRecType.tablename) {
        levelZeroJoinForeignKey = ck.attributeId;
      }
    });
  }
  // Only append for new columns
  if (
    Object.keys(matchingRecType?.lookup_column?.lookup_path || {}).length &&
    Object.keys(column.lookupPath || {}).length <= 2
  ) {
    const updatedRecLookup: RecordItem = {};
    let startLevel = levelZeroJoinForeignKey || isFileProjectCol ? 2 : 1;
    Object.keys(matchingRecType?.lookup_column?.lookup_path || {}).forEach((lookupLevel) => {
      const updatedLookupLevel = startLevel;
      updatedRecLookup[updatedLookupLevel] = matchingRecType?.lookup_column?.lookup_path?.[lookupLevel];
      if (lookupLevel === "0" && hasRecordBasicCols) {
        updatedRecLookup[updatedLookupLevel] = {
          ...updatedRecLookup[updatedLookupLevel],
          lookupColumns: [
            ...updatedRecLookup[updatedLookupLevel]?.lookupColumns,
            ...Object.keys(matchingRecType?.config || {})
          ]
        };
      }
      startLevel = startLevel + 1;
    });
    if (levelZeroJoinForeignKey && !isFileProjectCol) {
      updatedRecLookup["1"] = {
        lookupTableName: matchingRecType?.tablename || "",
        lookupType: LookupTypes.FOREIGN,
        lookupForeignKey: levelZeroJoinForeignKey,
        lookupColumns: []
      };
    }

    if (isFileProjectCol) {
      updatedRecLookup["1"] = {
        lookupForeignKey: "project_id",
        lookupType: LookupTypes.FOREIGN,
        lookupColumns: [],
        lookupTableName: "projects"
      };
    }
    return {
      ...column,
      lookupPath: {
        ...column.lookupPath,
        ...updatedRecLookup
      }
    };
  }
  // If only basic columns present (no lookup column in record_type)
  if (hasRecordBasicCols && !hasImageLookupCol) {
    const updatedRecLookup: RecordItem = { ...column.lookupPath };
    let updatedColOptions = null;
    if (Object.keys(column.lookupPath || {}).length > 2) {
      return column;
    }
    // ##HARDCODED condition for files_list_id support
    if (matchingRecType?.tablename === "files" && column?.lookupPath?.["0"]?.lookupForeignKey === "files_list_id") {
      updatedRecLookup["0"] = {
        ...column.lookupPath?.["0"],
        lookupColumns: []
      };
      updatedRecLookup["1"] = {
        lookupTableName: "files_lists_files",
        lookupType: LookupTypes.JOIN,
        lookupForeignKey: "files_lists_files",
        lookupColumns: ["is_featured"]
      };
      updatedRecLookup["2"] = {
        lookupTableName: matchingRecType?.tablename || "",
        lookupType: LookupTypes.FOREIGN,
        lookupForeignKey: "files_id",
        lookupColumns: Object.keys(matchingRecType?.config || {})
      };
    } else {
      updatedRecLookup["0"] = {
        ...column.lookupPath?.["0"],
        lookupColumns: levelZeroJoinForeignKey
          ? column?.lookupPath?.["0"]?.lookupColumns
          : Object.keys(matchingRecType?.config || {})
      };
      // For join tables we need to add the intermediate foreign key to the record type table
      // And basic columns will be added here
      if (levelZeroJoinForeignKey) {
        updatedRecLookup["1"] = {
          lookupTableName: matchingRecType?.tablename || "",
          lookupType: LookupTypes.FOREIGN,
          lookupForeignKey: levelZeroJoinForeignKey,
          lookupColumns: Object.keys(matchingRecType?.config || {})
        };
      }
    }

    if (Object.keys(column.columnOptionsLookUp || {})?.length) {
      updatedColOptions = {
        ...column.columnOptionsLookUp,
        ...updatedRecLookup
      };
    }
    return {
      ...column,
      lookupPath: {
        ...column.lookupPath,
        ...updatedRecLookup
      },
      columnOptionsLookUp: updatedColOptions || undefined
    };
  }
  return column;
};

export const getColumnDefaultValues = (
  columns: TableColumnType[],
  specialDefaults: {
    currentProjectId?: string;
    currentRecordId?: string;
    currentUserId?: string;
    newRecordId?: string;
  } = {}
) => {
  if (!columns?.length) return {};
  const defaultValues: RecordItem = {};
  columns.forEach((col) => {
    if (isBasicColumn(col)) {
      if (col.defaultValues?.value) {
        defaultValues[col.name] = col.defaultValues.value;
      }
    } else if (col.isLookup) {
      const firstLevel = col.lookupPath?.["0"];
      if (firstLevel?.lookupType === LookupTypes.FOREIGN && col.defaultValues?.value) {
        defaultValues[col.name] = col.defaultValues.value;
      } else if (isObject(col.defaultValues?.value)) {
        Object.keys(col.defaultValues?.value || {}).forEach((key) => {
          defaultValues[key] = (col.defaultValues?.value as RecordItem)?.[key];
        });
      }
    }
  });

  Object.keys(defaultValues).forEach((key) => {
    if (SPECIAL_DEFAULTS_VALUES.includes(defaultValues[key])) {
      const value = defaultValues[key];
      if (value === SPECIAL_DEFAULTS.CURRENT_PROJECT_ID) {
        if (specialDefaults.currentProjectId) {
          defaultValues[key] = specialDefaults.currentProjectId;
        }
      } else if (value === SPECIAL_DEFAULTS.CURRENT_RECORD_ID) {
        if (specialDefaults.currentRecordId) {
          defaultValues[key] = specialDefaults.currentRecordId;
        }
      } else if (value === SPECIAL_DEFAULTS.CURRENT_USER_ID) {
        if (specialDefaults.currentUserId) {
          defaultValues[key] = specialDefaults.currentUserId;
        }
      } else if (value === SPECIAL_DEFAULTS.NEW_RECORD_ID) {
        if (specialDefaults.newRecordId) {
          defaultValues[key] = specialDefaults.newRecordId;
        }
      }
    }
  });
  return defaultValues;
};

export const isFilesListsColumn = (column: TableColumnType) => {
  return !column?.isLookup
    ? column.name === "files_lists_id" || column.name === "files_list_id"
    : column.lookupPath?.["0"]?.lookupForeignKey === "files_list_id" ||
        column.lookupPath?.["0"]?.lookupForeignKey === "files_lists_id";
};

export const getCheckConditionRulePassedFn =
  ({
    columnOptions,
    view,
    row,
    recordTypesData,
    column
  }: {
    column: TableColumnType;
    columnOptions?: TableColumnType[];
    row: RecordItem;
    view?: ViewOption;
    recordTypesData?: ApiRecordType[];
  }) =>
  (colRule: ColumnConditionalRule) => {
    const colToEvaluate = columnOptions?.find((colOption) => colOption.id === colRule?.columnId);
    if (!colToEvaluate || !columnOptions?.length) return false;
    const valueToEvaluate = getColumnValueFromRowData({
      row,
      col: colToEvaluate,
      colOptions: columnOptions,
      recordTypesData
    });

    const visibility = getVisibilityByColumnRule(
      colRule,
      column.conditionalViewRules as ColumnRule,
      colToEvaluate,
      valueToEvaluate,
      view
    );

    if (visibility === column.conditionalViewRules?.ruleConfig?.visibility) {
      return true;
    }
    return false;
  };
